var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column overflow-hidden h-100"},[_c('vue-custom-scrollbar',{staticClass:"app-content-padding flex-grow-1 overflow-hidden h-100"},[_c('ts-page-title',{attrs:{"title":_vm.$t('customerLoanRequest.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('customerLoanRequest.pageTitle'),
                    href: '/admin/customer-loan-requests'
                },
                {
                    text: _vm.$t('create'),
                    active: true
                }
            ]}}),_c('div',{staticClass:"demo-spin-article"},[_c('ts-panel',[_c('form-wizard',{ref:"wizard",attrs:{"start-index":_vm.activeIndex,"color":"#00acac","title":'',"subtitle":'',"finishButtonText":"Finish","errorColor":"red"},on:{"update:startIndex":function($event){_vm.activeIndex=$event},"update:start-index":function($event){_vm.activeIndex=$event},"on-complete":_vm.onComplete},scopedSlots:_vm._u([{key:"step",fn:function(props){return _c('wizard-step',{attrs:{"tab":props.tab,"transition":props.transition,"index":props.index}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"slot":"active-step"},slot:"active-step"},[(props.index == 4)?_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":65,"width":65}}):_c('lottie',{attrs:{"options":_vm.defaultOptions2,"height":65,"width":65}})],1)])}},{key:"footer",fn:function(props){return [_c('div',{staticClass:"wizard-footer-left"},[(props.activeTabIndex == 0)?_c('wizard-button',{style:('background:#e2e2e2'),nativeOn:{"click":function($event){return _vm.$router.push({
                                        name: 'customer-loan-request'
                                    })}}},[_vm._v(_vm._s(_vm.$t("cancel")))]):_vm._e()],1),_c('div',{staticClass:"wizard-footer-left"},[(
                                    props.activeTabIndex > 0 &&
                                        !props.isLastStep
                                )?_c('wizard-button',{style:('background:#e2e2e2'),nativeOn:{"click":function($event){return props.prevTab()}}},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.previous")))]):_vm._e()],1),_c('div',{staticClass:"wizard-footer-right"},[(!props.isLastStep)?_c('wizard-button',{staticClass:"wizard-footer-right",style:(props.fillButtonStyle),attrs:{"disabled":_vm.nextStep},nativeOn:{"click":function($event){return props.nextTab()}}},[(_vm.nextStep)?_c('i',{staticClass:"fa fa-spinner spin mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.next"))+" ")]):_c('wizard-button',{staticClass:"wizard-footer-right finish-button",style:(props.fillButtonStyle),attrs:{"disabled":_vm.nextStep},nativeOn:{"click":function($event){return _vm.onComplete.apply(null, arguments)}}},[(_vm.nextStep)?_c('i',{staticClass:"fa fa-spinner spin mr-2"}):_vm._e(),_vm._v(" "+_vm._s(props.isLastStep ? _vm.$t("customerLoanRequest.done") : _vm.$t("customerLoanRequest.next"))+" ")])],1)]}}])},[_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":_vm.$t('customerLoanRequest.customerInformation'),"icon":"fas fa-users","before-change":function () { return _vm.validateStep('customerInformation'); }}},[_c('customer-information',{ref:"customerInformation",attrs:{"validation":_vm.errors,"clear":_vm.clear},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":_vm.$t('customerLoanRequest.loanInformation'),"icon":"fas fa-donate","before-change":function () { return _vm.validateStep('loanInformation'); }}},[_c('loan-information',{ref:"loanInformation",attrs:{"validation":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":_vm.$t(
                                'customerLoanRequest.sourceOfIncomeAndExpenses'
                            ),"icon":"fas fa-file-invoice-dollar"}},[_c('source-of-income-and-expenses',{ref:"sourceOfIncomeAndExpenses",attrs:{"validation":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":_vm.$t(
                                'customerLoanRequest.debtsOwedAndAbilityToBorrowMoney'
                            ),"icon":"fas fa-credit-card","before-change":_vm.onUpdate}},[_c('debts-owed-and-ability-to-borrow-money',{ref:"debtsOwedAndAbilityToBorrowMoney",attrs:{"validation":_vm.errors,"clear":_vm.clear},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":"Done","icon":" "}},[(_vm.activeIndex == 1)?_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":200,"width":200}}):_vm._e(),_c('show')],1)],1)],1)],1),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }